import React from 'react';
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom';
import { AiOutlineCalendar } from "react-icons/ai";
import { RiPriceTag3Line } from "react-icons/ri";
import mixpanel from "mixpanel-browser";

import * as Config from "../../../Config";
import * as Master from "../../../reference/Master";

import { categories } from '../../../reference/Category';

import './Category.css';

function Categories() {

   const { Meta } = Card;

   // return categories.map((category, i) => (
   //    <Link to={`/category/${category.code}`} key={i}>
   //       <Card
   //          key={i}
   //          hoverable
   //          style={{ width: 240 }}
   //          cover={
   //             <img
   //                alt={category.category}
   //                src={process.env.PUBLIC_URL + '/images' + category.imageUrl}
   //             />
   //          }
   //          className="home-category-item"
   //       >
   //          <Meta title={category.desc} />
   //       </Card>
   //    </Link>
   // ));

   // go to select transport type
   return categories.map((category, i) => (
      <Link className={`btn-select-transport-type-${category.code}`} to={`/select-transport-type/${category.code}`} key={i}>
         <Card
            key={i}
            hoverable
            style={{ width: 240 }}
            cover={
               <img
                  alt={category.category}
                  src={process.env.PUBLIC_URL + '/images' + category.imageUrl}
               />
            }
            className="home-category-item"
         >
            <Meta title={category.desc} />
         </Card>
      </Link>
   ));

   // const onWhatsapp = (type) => {
   //    window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI(`Halo Lintas Admin, saya mau cek harga / pesan angkutan ${type}.`));

   //    mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
   //    mixpanel.track("Event Select Transport WA");
   // };

   // // check price / order from whatsapp
   // return categories.map((category, i) => (
   //    <Link className={`btn-select-transport-type-${category.code}`} to={`#`} onClick={() => onWhatsapp(category.desc)} key={i}>
   //       <Card
   //          key={i}
   //          hoverable
   //          style={{ width: 240 }}
   //          cover={
   //             <img
   //                alt={category.category}
   //                src={process.env.PUBLIC_URL + '/images' + category.imageUrl}
   //             />
   //          }
   //          className="home-category-item"
   //       >
   //          <Meta title={category.desc} />
   //       </Card>
   //    </Link>
   // ));
}

function Category() {
   const onCheckPrice = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau cek harga"));
      // modalContext?.setCheckPrice(true);

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Check Price WA");

      // gtm();
   };

   const onBookNow = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau pesan angkutan"));
      // modalContext?.setNewShipment(true);

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Book Now WA");

      // gtm();
   };

   return (
      <div className="home-category">
         <div className="home-category-title">Kategori Angkutan</div>
         <div className="home-category-description">
            Pilih penyedia jasa angkutan sesuai kebutuhan anda
         </div>
         <div className="home-category-content">
            <Categories />
         </div>
         <div className="home-category-buttons">
            <Button
               className="category-btn"
               type="primary"
               icon={<RiPriceTag3Line style={style.icon} />}
               size="large"
               onClick={onCheckPrice}
            >
               Cek Harga via WhatsApp
            </Button>
            <Button
               className="category-btn"
               type="primary"
               icon={<AiOutlineCalendar style={style.icon} />}
               size="large"
               onClick={onBookNow}
            >
               Pesan via WhatsApp
            </Button>
         </div>
      </div>
   );
}

const style = {
   icon: {
      fontSize: 17, position: 'relative', top: 2, marginRight: 7
   }
}

export default Category;
