import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';

import Tracking from './Tracking';
import { Header } from './components/header';
import { Footer, BottomNavigation } from './components/footer';
import { Category } from './components/category';
import { Home, LoginHome, AboutUs, Browser } from './components/home';
import { Providers } from './components/admin/provider';
import { Transporters, TransporterForm } from './components/admin/transporter';
import { Carriers, CarrierForm } from './components/admin/carrier';
import { Services, ServiceForm } from './components/admin/service';
import { Sites, SiteForm } from './components/admin/site';
import { Vouchers, VoucherForm } from './components/admin/voucher';
import { Users, UserForm } from './components/admin/user';
import { Customers } from './components/admin/customer';
import { Shipment, ShipmentDetail } from './components/shipment';
import { Admin } from './components/admin/Admin';
import { SearchServices, SearchInfo } from './components/search';
import { NewBooking, Booking, BookingRegular, SelectTransportType, ChangeTransportType, ChangeCategory, SelectProviderTransportType, NewShipment, SelectPlaceMap, SelectProvider, ConfirmShipment, ProviderInfo } from './components/booking';
import { Login, Otp, RegisterCustomer, RegisterProvider, RegisterTransporter, RegisterSuccess, Logout, UpdateProvider, UpdateUser, UpdateCustomer, Forward, SelectProfile, UpdateBank } from './components/login';
import { LoadingView, AlertView, ConfirmView } from './components/common';

import { CacheService, ReferenceService, VoucherService } from './service';

import CameraTest from './components/common/test/CameraTest';
import LocationTest from './components/common/test/LocationTest';

import * as ShipmentRef from './reference/Shipment';
import * as PlaceRef from './reference/Place';

import * as Master from './reference/Master';

import { ModalContext } from './hooks/ModalContext';

import './Routing.css';

import ReviewProvider from './components/review/ReviewProvider';
import ReviewTransporter from './components/review/ReviewTransporter';
import ReviewCustomer from './components/review/ReviewCustomer';

import TermsConditions from './components/common/TermsConditions';
import PrivacyPolicy from './components/common/PrivacyPolicy';
import TrendAnalytic from './components/analytic/TrendAnalytic';
import PieAnalytic from './components/analytic/PieAnalytic';
import BarAnalytic from './components/analytic/BarAnalytic';

function Routing(props) {

   const [selectedMenu, setSelectedMenu] = useState({ title: 'Beranda' });
   const [showBackNavigation, setShowBackNavigation] = useState(false);
   const [showBottomNavigation, setShowBottomNavigation] = useState(true);
   const [showLoading, setShowLoading] = useState(false);
   const [hideLoading, setHideLoading] = useState(false);
   const [loadingText, setLoadingText] = useState('');

   const [showAlert, setShowAlert] = useState(false);
   const [alertType, setAlertType] = useState(Master.ALERT_SUCCESS);
   const [alertTitle, setAlertTitle] = useState('');
   const [alertDesc, setAlertDesc] = useState('');
   const [alertMsg, setAlertMsg] = useState('');
   const [alertSrc, setAlertSrc] = useState('');
   const [confirm, setConfirm] = useState();
   const [showConfirm, setShowConfirm] = useState(false);
   const [isCheckPrice, setCheckPrice] = useState(false);
   const [isNewShipment, setNewShipment] = useState(false);
   const [confirmDesc, setConfirmDesc] = useState('');
   const [anchor, setAnchor] = useState(useRef(null));
   const [harbor, setHarbor] = useState(CacheService.getHarbor());

   const [profile] = useState({});
   const [profiles, setProfiles] = useState(CacheService.getProfiles());
   const [login, setLogin] = useState(CacheService.getUser() ? true : false);
   const [user, setUser] = useState(CacheService.getUser());

   // CacheService.setNewShipment(null);
   // CacheService.setShipment(null);
   // CacheService.setUser(null);

   const [shipment] = useState(CacheService.getNewShipment() ? CacheService.getNewShipment() : ShipmentRef.newShipment);
   //console.log('shipment: ', JSON.stringify(shipment, null, 2));

   const [voucherCode] = useState('#LintasTanahAir');
   const [voucher, setVoucher] = useState();

   const [place] = useState(PlaceRef.defaultPlace);
   const [showSelectPlaceMap, setShowSelectPlaceMap] = useState(true);

   const MainHeader = withRouter(props =>
      <Header login={login} user={user} showBackNavigation={showBackNavigation} setSelectedMenu={setSelectedMenu} {...props} />
   );

   const [size, setSize] = useState([0, 0]);
   useLayoutEffect(() => {
      function updateSize() {
         setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
   }, []);

   ReferenceService.initTransportTypes();

   useLayoutEffect(() => {
      setShowBottomNavigation(props.stdHeight === window.innerHeight);
      //console.log('showBottomNavigation: ' + props.stdHeight === window.innerHeight);
   }, [props.stdHeight, size]);

   useEffect(() => {
      VoucherService.getActiveVoucher(voucherCode)
         .then((voucher) => {
            setVoucher(voucher);
         })
         .catch((error) => {
            console.error(error);
         });
   }, [voucherCode])

   useEffect(() => {
      console.log("routing path: ", window.location.pathname)
   }, [props])

   return (
      <Router>
         <ModalContext.Provider
            value={{
               setShowLoading: setShowLoading,
               setHideLoading: setHideLoading,
               setLoadingText: setLoadingText,
               setShowAlert: setShowAlert,
               setAlertType: setAlertType,
               setAlertTitle: setAlertTitle,
               setAlertDesc: setAlertDesc,
               setAlertMsg: setAlertMsg,
               setAlertSrc: setAlertSrc,
               setLogin: setLogin,
               setShowConfirm: setShowConfirm,
               setConfirmDesc: setConfirmDesc,
               setConfirm: setConfirm,
               setAnchor: setAnchor,
               setHarbor: setHarbor,
               setCheckPrice: setCheckPrice,
               setNewShipment: setNewShipment,
            }}
         >
            <Tracking {...props} />
            <LoadingView show={showLoading} setShow={setShowLoading} hide={hideLoading} setHide={setHideLoading} text={loadingText} />
            <AlertView
               show={showAlert}
               setShow={setShowAlert}
               title={alertTitle}
               type={alertType}
               desc={alertDesc}
               msg={alertMsg}
               src={alertSrc}
               anchor={anchor}
               setAnchor={setAnchor}
            />
            <ConfirmView
               show={showConfirm}
               setShow={setShowConfirm}
               desc={confirmDesc}
               confirm={confirm}
            />
            <NewBooking
               show={isCheckPrice || isNewShipment}
               isCheckPrice={isCheckPrice}
               isNewShipment={isNewShipment}
            />
            <MainHeader {...props} />
            <Route exact path="/" render={
               (props) => <Home setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />} />
            <Route exact path="/webview" render={
               (props) => <Home webview={true} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />} />
            <Route exact path="/home" render={
               (props) => <Home setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />} />
            <Route exact path="/browser" render={
               (props) => <Browser setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />} />
            <Route exact path="/home/login" render={
               (props) => <LoginHome showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} setSelectedMenu={setSelectedMenu} />} />
            <Route exact path="/category/:id" render={
               (props) => <Category setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/booking" render={
               (props) => <Booking setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} />} />
            <Route exact path="/test/booking" render={
               (props) => <Booking setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} />} />
            <Route exact path="/select-regular-shipment/:category" render={
               (props) => <BookingRegular setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} />} />
            <Route exact path="/select-transport-type/:category" render={
               (props) => <SelectTransportType shipment={shipment} setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation}  {...props} />} />
            <Route exact path="/test/select-transport-type/:category" render={
               (props) => <SelectTransportType shipment={shipment} setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation}  {...props} />} />
            <Route exact path="/change-transport-type/:category" render={
               (props) => <ChangeTransportType shipment={shipment} setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation}  {...props} />} />
            <Route exact path="/change-category" render={
               (props) => <ChangeCategory setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} />} />
            <Route exact path="/booking/provider/:id" render={
               (props) => <SelectProviderTransportType setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} shipment={shipment} {...props} />} />
            <Route exact path="/new-shipment/:transportType" render={
               (props) => <NewShipment shipment={shipment} setHarbor={setHarbor} setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/select-place-map" render={
               () => <SelectPlaceMap
                  show={showSelectPlaceMap}
                  setShow={setShowSelectPlaceMap}
                  place={place}
                  shipment={shipment}
               />} />
            <Route exact path="/select-provider" render={
               (props) => <SelectProvider shipment={shipment} voucher={voucher} harbor={harbor} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/confirm-shipment" render={
               (props) => <ConfirmShipment shipment={shipment} voucher={voucher} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/provider/:id" render={
               (props) => <ProviderInfo setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} shipment={shipment} {...props} />} />
            <Route exact path="/shipment" render={
               (props) => <Shipment showBottomNavigation={showBottomNavigation} setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/shipment/status/:status/:desc" render={
               (props) => <Shipment setShowBackNavigation={setShowBackNavigation} showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/shipment-detail/:id" render={
               (props) => <ShipmentDetail setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/new-shipment-detail/:id" render={
               (props) => <ShipmentDetail setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/shipment/payment" render={
               (props) => <ShipmentDetail setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route path="/login/:path" render={
               (props) => <Login profile={profile} showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/otp" render={(props) => <Otp profile={profile} setProfiles={setProfiles} setUser={setUser} showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} />} />
            <Route exact path="/select-profile" render={(props) => <SelectProfile profiles={profiles} setUser={setUser} />} />
            <Route exact path="/forward/:uid/:role/:shipmentId" render={
               (props) => <Forward {...props} />} />
            <Route
               exact path="/register/customer"
               render={(props) => <RegisterCustomer setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} profile={profile} setUser={setUser} {...props} />}
            />
            <Route
               exact path="/register/customer/:mobile"
               render={(props) => <RegisterCustomer setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} profile={profile} setUser={setUser} {...props} />}
            />
            <Route
               exact path="/register/provider"
               render={() => <RegisterProvider setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} profile={profile} setUser={setUser} />}
            />
            <Route
               exact path="/register/transporter"
               render={() => <RegisterTransporter setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} profile={profile} setUser={setUser} />}
            />
            <Route exact path="/register-success" render={() => <RegisterSuccess />} />
            <Route
               exact path="/admin"
               render={() => <Admin setUser={setUser} />}
            />
            <Route
               exact path="/admin/provider"
               render={() => <Providers showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setUser={setUser} />}
            />
            <Route
               exact path="/admin/transporter"
               render={() => <Transporters showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />}
            />
            <Route
               exact path="/admin/transporter/add"
               render={(props) => <TransporterForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route exact path="/admin/transporter/update/:id" render={
               (props) => <TransporterForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route
               exact path="/admin/carrier"
               render={() => <Carriers showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />}
            />
            <Route
               exact path="/admin/carrier/add"
               render={(props) => <CarrierForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route exact path="/admin/carrier/update/:id" render={
               (props) => <CarrierForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route
               exact path="/admin/service"
               render={() => <Services showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />}
            />
            <Route
               exact path="/admin/service/add"
               render={(props) => <ServiceForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route exact path="/admin/service/update/:id" render={
               (props) => <ServiceForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route
               exact path="/admin/site"
               render={() => <Sites showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />}
            />
            <Route
               exact path="/admin/site/add"
               render={(props) => <SiteForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route exact path="/admin/site/update/:id" render={
               (props) => <SiteForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route
               exact path="/admin/customer"
               render={() => <Customers showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} setUser={setUser} />}
            />
            <Route
               exact path="/admin/voucher"
               render={() => <Vouchers showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />}
            />
            <Route
               exact path="/admin/voucher/add"
               render={(props) => <VoucherForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route exact path="/admin/voucher/update/:id" render={
               (props) => <VoucherForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route
               exact path="/admin/user"
               render={() => <Users showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} />}
            />
            <Route
               exact path="/admin/user/add"
               render={(props) => <UserForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route exact path="/admin/user/update/:id" render={
               (props) => <UserForm setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/admin/review" render={
               (props) => <SelectProviderTransportType setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} shipment={shipment} {...props} />} />
            <Route exact path="/admin/review/new-shipment/:transportType" render={
               (props) => <NewShipment shipment={shipment} setHarbor={setHarbor} setShowBackNavigation={setShowBackNavigation} setShowBottomNavigation={setShowBottomNavigation} {...props} />} />
            <Route exact path="/admin/review/provider/:id" render={
               (props) => <ProviderInfo setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} shipment={shipment} {...props} />} />
            <Route
               exact path="/profile/provider"
               render={(props) => <UpdateProvider setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route
               exact path="/profile/provider/bank"
               render={(props) => <UpdateBank setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route
               exact path="/profile/customer"
               render={(props) => <UpdateCustomer setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route
               exact path="/profile"
               render={(props) => <UpdateUser setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />}
            />
            <Route
               exact path="/logout"
               render={() => (
                  <Logout setLogin={setLogin} setUser={setUser} />
               )}
            />
            <Route exact path="/review/provider" render={
               (props) => <ReviewProvider setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/review/transporter" render={
               (props) => <ReviewTransporter setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/review/customer" render={
               (props) => <ReviewCustomer setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/tnc" render={
               (props) => <TermsConditions setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/privacy" render={
               (props) => <PrivacyPolicy setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/about_us" render={
               (props) => <AboutUs setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />

            <Route exact path="/analytic/trend" render={
               (props) => <TrendAnalytic showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/analytic/pie" render={
               (props) => <PieAnalytic showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/common/camera-test/:container" render={
               (props) => <CameraTest {...props} />} />
            <Route exact path="/common/location-test" render={
               (props) => <LocationTest {...props} />} />
            <Route exact path="/analytic/bar" render={
               (props) => <BarAnalytic showBottomNavigation={showBottomNavigation} setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/muatan" render={
               (props) => <SearchInfo setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />
            <Route exact path="/muatan/cari" render={
               (props) => <SearchServices setShowBottomNavigation={setShowBottomNavigation} setShowBackNavigation={setShowBackNavigation} {...props} />} />

            {showBottomNavigation && <BottomNavigation user={user} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />}
            <Footer user={user} />
         </ModalContext.Provider>
      </Router>
   );
}

export default Routing;
