import { Option } from "../model";

export const APP_VERSION = "1.0.0";
export const MOBILE_LINTAS = "628111656401";
export const MOBILE_LINTAS_LOCAL = "08111656401";
export const MOBILE_LINTAS_PARTNERSHIP = "62811349800";

export const SHIPMENT_STATUS_NEW_ORDER = "NEWORDER";
export const SHIPMENT_STATUS_PENDING_PAYMENT = "PENDINGPY";
export const SHIPMENT_STATUS_CONFIRM_PAYMENT = "CONFRMPY";
export const SHIPMENT_STATUS_CONFIRM_NO_PAYMENT = "CONFRMNP";
export const SHIPMENT_STATUS_DELIVERY_IN_PROGRESS = "DLVRPRGS";
export const SHIPMENT_STATUS_DELIVERY_COMPLETED = "DLVRCOMP";
export const SHIPMENT_STATUS_DELIVERY_CONFIRMED = "DLVRCONF";
export const SHIPMENT_STATUS_PAYMENT_TRANSFERED = "TRANSPY";
export const SHIPMENT_STATUS_DECLINE = "DECLINE";
export const SHIPMENT_STATUS_CANCEL = "CANCEL";
export const SHIPMENT_STATUS_CANCEL_NO_CONFIRMATION = "CNCLNOCF";
export const SHIPMENT_STATUS_CANCEL_NO_PAYMENT = "CNCLNOPY";
export const SHIPMENT_STATUS_COMPLAINT = "COMPLNT";
export const SHIPMENT_STATUS_COMPLAINT_RESOLVED = "COMPLNTRSD";

export const SERVICE_PRICE_SCHEME_WEIGHT = "WEIGHT";
export const SERVICE_PRICE_SCHEME_WEIGHT_DISTANCE = "WEIGHT_DISTANCE";

export const VOUCHER_TYPE_AMOUNT = "AMOUNT";
export const VOUCHER_TYPE_PERCENT = "PERCENT";

export const PAYMENT_TYPE_FULL = "FULL";
export const PAYMENT_TYPE_PARTIAL = "PARTIAL";
export const PAYMENT_TYPE_PENDING = "PENDING";

export const PAYMENT_METHOD_CASH = "CASH";
export const PAYMENT_METHOD_TRANSFER = "TRANSFER";

export const PAYMENT_STATUS_PENDING = "PENDING";
export const PAYMENT_STATUS_CONFIRM = "CONFIRM";
export const PAYMENT_STATUS_PARTIAL = "PARTIAL";
export const PAYMENT_STATUS_COMPLETED = "COMPLETE";

export const CONFIRM_WITH_BOOKING_FEE = "BOOKING_FEE_YES";
export const CONFIRM_WITHOUT_BOOKING_FEE = "BOOKING_FEE_NO";

export const TOP_YES = "TOP_YES";
export const TOP_NO = "TOP_NO";

export const RESCHEDULE_STATUS_REQUEST_PENDING = "REQPDG";
export const RESCHEDULE_STATUS_REQUEST_APPROVED = "REQAPR";
export const RESCHEDULE_STATUS_REQUEST_REJECT = "REQRJC";

export const RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING = "CSTREQPDG";
export const RESCHEDULE_STATUS_CUSTOMER_REQUEST_APPROVED = "CSTREQAPR";
export const RESCHEDULE_STATUS_CUSTOMER_REQUEST_REJECT = "CSTREQRJC";
export const RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING = "PVDREQPDG";
export const RESCHEDULE_STATUS_PROVIDER_REQUEST_APPROVED = "PVDREQAPR";
export const RESCHEDULE_STATUS_PROVIDER_REQUEST_REJECT = "PVDREQRJC";

export const STEP_STATUS_PENDING = "P";
export const STEP_STATUS_COMPLETED = "C";
export const STEP_STATUS_ALL = "A";

export const STEP_CODE_TRAVEL_TO_ORIGIN = "TRVORG";
export const STEP_CODE_ARRIVE_AT_ORIGIN = "ARVORG";
export const STEP_CODE_START_LOADING = "STRLOD";
export const STEP_CODE_COMPLETE_LOADING = "CMPLOD";
export const STEP_CODE_TRAVEL_TO_DESTINATION = "TRVDES";
export const STEP_CODE_ARRIVE_AT_DESTINATION = "ARVDES";
export const STEP_CODE_TRAVEL_TO_TRANSIT = "TRVTRS";
export const STEP_CODE_ARRIVE_AT_TRANSIT = "ARVTRS";
export const STEP_CODE_START_UNLOADING = "STRUNL";
export const STEP_CODE_COMPLETE_UNLOADING = "CMPUNL";
export const STEP_CODE_START_TRANSIT = "STRTRS";
export const STEP_CODE_COMPLETE_TRANSIT = "CMPTRS";

export const ROUTE_STATUS_PENDING = "P";
export const ROUTE_STATUS_IN_PROGRESS = "I";
export const ROUTE_STATUS_COMPLETED = "C";

export const ACTION_ADD_SHIPMENT = "ADDSHPMT";
export const ACTION_ADMIN_PROVIDER = "ADMCARIER";
export const ACTION_ADMIN_CARRIER = "ADMCARIER";
export const ACTION_ADMIN_TRANSPORTER = "ADMTRANS";
export const ACTION_ADMIN_SERVICE = "ADMSRVCE";
export const ACTION_MANAGE_EXPENSE = "MANGEXPN";
export const ACTION_ANALYTIC_PIE = "ANALYTIC";
export const ACTION_ANALYTIC_TREND = "TREND";

export const PARTNER_SUMMARY_NEW_ORDER = "NEWORDER";
export const PARTNER_SUMMARY_PENDING_PAYMENT = "PENDINGPY";
export const PARTNER_SUMMARY_CONFIRM_PAYMENT = "CONFRMPY";
export const PARTNER_SUMMARY_DELIVERY_IN_PROGRESS = "DLVRPRGS";
export const PARTNER_SUMMARY_DELIVERY_COMPLETED = "DLVRCOMP";
export const PARTNER_SUMMARY_PAYMENT_TRANSFERED = "TRANSPY";
export const PARTNER_SUMMARY_RATING = "PTRATING";
export const PARTNER_SUMMARY_MESSAGE = "PTMESSG";
export const PARTNER_SUMMARY_BOOKING = "BOOKING";

export const USER_ROLE_CUSTOMER = "CUSTOMER";
export const USER_ROLE_ADMIN = "ADMIN";
export const USER_ROLE_PROVIDER = "PROVIDER";
export const USER_ROLE_TRANSPORTER = "TRANSPORTER";

export const CARRIER_STATUS_PENDING_APPROVAL = "PENDING";
export const CARRIER_STATUS_APPROVED = "APPROVED";
export const CARRIER_STATUS_REJECTED = "REJECTED";
export const CARRIER_STATUS_ONHOLD = "ONHOLD";

export const CATEGORY_PICKUP = "PCK";
export const CATEGORY_VAN = "VAN";
export const CATEGORY_COLT_DIESEL = "COD";
export const CATEGORY_COLT_DIESEL_ENGKEL = "CDE";
export const CATEGORY_COLT_DIESEL_DOUBLE = "CDD";
export const CATEGORY_FUSO = "FSO";
export const CATEGORY_TRONTON = "TRO";
export const CATEGORY_WINGBOX = "WBX";
export const CATEGORY_TRAILER = "TRL";
export const CATEGORY_CONTAINER = "CON";

export const CATEGORY_REGULAR = "REG";
export const CATEGORY_MONTHLY = "MON";

export const TRANSPORT_TYPE_PUPLBK = "PUPLBK";
export const TRANSPORT_TYPE_PUPBOX = "PUPBOX";
export const TRANSPORT_TYPE_PUPREF = "PUPREF";
export const TRANSPORT_TYPE_VANVAN = "VANVAN";
export const TRANSPORT_TYPE_CDEBOX = "CDEBOX";
export const TRANSPORT_TYPE_CDEBAK = "CDEBAK";
export const TRANSPORT_TYPE_CDEREF = "CDEREF";
export const TRANSPORT_TYPE_CDELBK = "CDELBK";
export const TRANSPORT_TYPE_CDDBOX = "CDDBOX";
export const TRANSPORT_TYPE_CDDBAK = "CDDBAK";
export const TRANSPORT_TYPE_CDDLBK = "CDDLBK";
export const TRANSPORT_TYPE_CDDCAR = "CDDCAR";
export const TRANSPORT_TYPE_CDDLBX = "CDDLBX";
export const TRANSPORT_TYPE_CDDMCR = "CDDMCR";
export const TRANSPORT_TYPE_CDDBKL = "CDDBKL";
export const TRANSPORT_TYPE_CDDWTR = "CDDWTR";
export const TRANSPORT_TYPE_CDDREF = "CDDREF";
export const TRANSPORT_TYPE_FSOBOX = "FSOBOX";
export const TRANSPORT_TYPE_FSOBAK = "FSOBAK";
export const TRANSPORT_TYPE_TROBOX = "TROBOX";
export const TRANSPORT_TYPE_TROBAK = "TROBAK";
export const TRANSPORT_TYPE_TROFLT = "TROFLT";
export const TRANSPORT_TYPE_TROCAR = "TROCAR";
export const TRANSPORT_TYPE_TROREF = "TROREF";
export const TRANSPORT_TYPE_WBXCDE = "WBXCDE";
export const TRANSPORT_TYPE_WBXCDD = "WBXCDD";
export const TRANSPORT_TYPE_WBXTRO = "WBXTRO";
export const TRANSPORT_TYPE_TRLSHR = "TRLSHR";
export const TRANSPORT_TYPE_TRLBX2 = "TRLBX2";
export const TRANSPORT_TYPE_TRLLNG = "TRLLNG";
export const TRANSPORT_TYPE_TRLBX4 = "TRLBX4";
export const TRANSPORT_TYPE_TRLLWB = "TRLLWB";
export const TRANSPORT_TYPE_TRLCMB = "TRLCMB";
export const TRANSPORT_TYPE_COND20 = "COND20";
export const TRANSPORT_TYPE_COND40 = "COND40";
export const TRANSPORT_TYPE_CONC20 = "CONC20";
export const TRANSPORT_TYPE_CONC40 = "CONC40";

export const CONFIRM_NO = "ACCEPT_NO";
export const CONFIRM_ACCEPT_SHIPMENT = "ACCEPT_SHIPMENT";

export const ALERT_ERROR = "ERROR";
export const ALERT_WARNING = "WARNING";
export const ALERT_SUCCESS = "SUCCESS";
export const ALERT_INFO = "INFO";

export const ALERT_ERROR_TITLE = "Perhatian";
export const ALERT_WARNING_TITLE = "Perhatian";
export const ALERT_SUCCESS_TITLE = "Sukses";
export const ALERT_CONFIRM_TITLE = "Konfirmasi";
export const ALERT_INFO_TITLE = "Informasi";

export const ENTITY_TYPE_PERSONAL = "PERSONL";
export const ENTITY_TYPE_CORPORATE = "COMPANY";

export const TRANSIT_TYPE_LOADING = "LOADNG";
export const TRANSIT_TYPE_UNLOADING = "UNLDNG";

export const IMAGE_STATUS_DONE = "done";
export const IMAGE_STATUS_IN_PROGRESS = "in progress";

export const PATH_CUSTOMER = "customer";
export const PATH_PROVIDER = "provider";
export const PATH_TRANSPORTER = "transporter";
export const PATH_BOOKING = "booking";
export const PATH_ADMIN = "admin";
export const PATH_REFERRAL = "referral";

export const ROLE_CUSTOMER = "CUSTOMER";
export const ROLE_PROVIDER = "PROVIDER";
export const ROLE_TRANSPORTER = "TRANSPORTER";
export const ROLE_ADMIN = "ADMIN";

export const PROVIDER_ROLE_ADMIN = "ADMIN";
export const PROVIDER_ROLE_GENERAL = "GENERAL";

export const STATUS_PENDING_DOCUMENT = "D";
export const STATUS_PENDING = "P";
export const STATUS_ACTIVE = "A";
export const STATUS_INACTIVE = "I";
export const STATUS_VERIFIED = "V";

export const SERVICE_TYPE_TRANSPORT = "TRANS";
export const SERVICE_TYPE_ADDON = "ADDON";

export const SERVICE_CODE_TKBM = "AOTKBM";

export const UOM_KM = "KM";
export const UOM_UNIT = "UN";

export const YES = "Y";
export const NO = "N";

export const IMAGE_ALT_PERSON = "IMG-PERSON";
export const IMAGE_ALT_ORGANISATION = "IMG-ORGANISATION";
export const IMAGE_ALT_TRUCK = "IMG-TRUCK";

export const MILISECONDS = 1000;

export const CANCEL_FEE_AMOUNT = 100000;

export const FEE_CODE_COLLECTION = "COLLCT";
export const FEE_CODE_TRANSPORT = "TRANS";
export const FEE_CODE_TKBM = "TKBMS";
export const FEE_CODE_TOLL = "TOLLS";
export const FEE_CODE_ADMIN = "ADMIN";
export const FEE_CODE_FERRY = "FERRY";

export const FEE_TYPE_PLATFORM = "PLT";
export const FEE_TYPE_PROVIDER = "PVD";

export const FEE_GROUP_TYPE_SERVICE = "SRV";
export const FEE_GROUP_TYPE_ADDON = "ADO";
export const FEE_GROUP_TYPE_OTHERS = "OTH";
export const FEE_GROUP_TYPE_DISCOUNT = "DSC";
export const FEE_GROUP_TYPE_TAX = "TAX";

export const MESSAGE_TYPE_PUBLISH = "P";
export const MESSAGE_TYPE_SUBSCRIBE = "S";
export const MESSAGE_TYPE_UPDATE = "U";

export const FEE_GROUP_ORDER_SERVICE = 1;
export const FEE_GROUP_ORDER_ADDON = 2;
export const FEE_GROUP_ORDER_OTHERS = 3;
export const FEE_GROUP_ORDER_DISCOUNT = 4;
export const FEE_GROUP_ORDER_TAX = 5;

export const DECLINE_REASON_MAINTENANCE = "MAINTNANCE";
export const DECLINE_REASON_UNABLE_CONTACT_CUSTOMER = "UNBLETOCTC";
export const DECLINE_REASON_PICK_UP_LOCATION_TOO_FAR = "LOCATOOFAR";
export const DECLINE_REASON_FULL_BOOK = "FULLBOOKED";
export const DECLINE_REASON_INCORRECT_ADDRESS = "INCRTADDRS";
export const DECLINE_REASON_OTHERS = "OTHERSRSON";

export const DECLINE_REASON_MAINTENANCE_DESC = "Kendaraan dalam perbaikan";
export const DECLINE_REASON_UNABLE_CONTACT_CUSTOMER_DESC = "Tidak dapat menghubungi pelanggan";
export const DECLINE_REASON_PICK_UP_LOCATION_TOO_FAR_DESC = "Lokasi terlalu jauh";
export const DECLINE_REASON_FULL_BOOK_DESC = "Kendaraan telah dibooking oleh pelanggan lain";
export const DECLINE_REASON_INCORRECT_ADDRESS_DESC = "Alamat yang diberikan tidak sesuai";
export const DECLINE_REASON_OTHERS_DESC = "Lainnya";

export const COMPLAINT_TYPE_DAMAGE = "DMGE";
export const COMPLAINT_TYPE_LOST = "LOST";
export const COMPLAINT_TYPE_LATE = "LATE";

export const COMPLAINT_TYPE_DAMAGE_DESC = "Kerusakan Barang";
export const COMPLAINT_TYPE_LOST_DESC = "Kehilangan Barang";
export const COMPLAINT_TYPE_LATE_DESC = "Keterlambatan Pengiriman";

export const COMPLAINT_RESOLVE_NOT_VALID = "NOTVALID";
export const COMPLAINT_RESOLVE_COMPENSATE = "COMPENSATE";

export const COMPLAINT_RESOLVE_NOT_VALID_DESC = "Keluhan tidak dapat diproses";
export const COMPLAINT_RESOLVE_COMPENSATE_DESC = "Kompensasi";

export const COMPLAINT_STATUS_PENDING_RESPONSE = "PENDING";
export const COMPLAINT_STATUS_TRASPORTER_RESPONSE = "TRANSRESP";
export const COMPLAINT_STATUS_PROVIDER_RESPONSE = "PRVDRRESP";
export const COMPLAINT_STATUS_RESOLVED = "RESOLVED";

export const COMPLAINT_IMAGE_TYPE_CUSTOMER = "CUSTM";
export const COMPLAINT_IMAGE_TYPE_TRANSPORTER = "TRANS";
export const COMPLAINT_IMAGE_TYPE_PROVIDER = "PRVDR";

export const IMAGE_TRANSPARENT = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export const COMS_RATIO = 0.1;
export const GOOGLE_MAPS_DURATION_RATIO = 1.5;

export class Master {
   code: string;
   desc: string;
}

export const verificationStatusOptions = [
   { label: "Terverifikasi", value: STATUS_VERIFIED },
   { label: "Pending", value: STATUS_PENDING },
];

export const serviceVerificationStatusOptions = [
   { label: "Data Belum Lengkap", value: STATUS_PENDING },
   { label: "Pending Dokumen / Administrasi", value: STATUS_PENDING_DOCUMENT },
   { label: "Layanan Terverifikasi", value: STATUS_VERIFIED },
];

export const statusOptions = [
   { label: "Aktif", value: STATUS_ACTIVE },
   { label: "Tidak Aktif", value: STATUS_INACTIVE },
];

export const providerRoleOptions = [
   { label: "Admin", value: PROVIDER_ROLE_ADMIN },
   { label: "Non Admin", value: PROVIDER_ROLE_GENERAL },
];

export const servicePriceSchemeOptions = [
   { label: "Berat", value: SERVICE_PRICE_SCHEME_WEIGHT },
   { label: "Berat + Jarak", value: SERVICE_PRICE_SCHEME_WEIGHT_DISTANCE },
];

export const voucherTypeOptions = [
   { label: "Nominal", value: VOUCHER_TYPE_AMOUNT },
   { label: "Persen", value: VOUCHER_TYPE_PERCENT },
];

export const paymentTypeOptions = [
   { label: "Bayar Penuh", value: PAYMENT_TYPE_FULL },
   { label: "Bayar Sebagian", value: PAYMENT_TYPE_PARTIAL },
];

export const paymentMethodOptions = [
   { label: "Transfer Bank", value: PAYMENT_METHOD_TRANSFER },
   { label: "Cash / Tunai", value: PAYMENT_METHOD_CASH },
];

export const confirmTypeOptions = [
   { label: "Bayar DP", value: CONFIRM_WITH_BOOKING_FEE },
   { label: "Tanpa DP", value: CONFIRM_WITHOUT_BOOKING_FEE },
];

export const topOptions = [
   { label: "Tanpa ToP", value: TOP_NO },
   { label: "ToP", value: TOP_YES },
];

export const entityTypes: Master[] = [
   { code: ENTITY_TYPE_PERSONAL, desc: "Pribadi" },
   { code: ENTITY_TYPE_CORPORATE, desc: "Perusahaan" },
];

export const transitTypes: Master[] = [
   { code: TRANSIT_TYPE_LOADING, desc: "Muat / Loading" },
   { code: TRANSIT_TYPE_UNLOADING, desc: "Bongkar / Unloading" },
];

export const EXPENSE_TYPE_FUEL = "FUEL";
export const EXPENSE_TYPE_SPARE_PARTS = "SPARE_PARTS";

export const expenseTypes: Master[] = [
   { code: EXPENSE_TYPE_FUEL, desc: "Bahan Bakar" },
   { code: EXPENSE_TYPE_SPARE_PARTS, desc: "Suku Cadang" },
];

export const EXPENSE_STATUS_PENDING = "PENDING_APPROVAL";
export const EXPENSE_STATUS_APPROVED = "APPROVED";

export const expenseStatuses: Master[] = [
   { code: EXPENSE_STATUS_PENDING, desc: "Menunggu Disetujui" },
   { code: EXPENSE_STATUS_APPROVED, desc: "Disetujui" },
];

export const cargoTypeOptions = [
   {
      value: "Bahan Pangan/Sembako",
      label: "Bahan Pangan/Sembako",
   },
   {
      value: "Produk kemasan/manufaktur",
      label: "Produk kemasan/manufaktur",
   },
   {
      value: "Barang pindahan/Muatan umum",
      label: "Barang pindahan/Muatan umum",
   },
   {
      value: "Bahan kontruksi/bangunan",
      label: "Bahan kontruksi/bangunan",
   },
   {
      value: "Hasil pertanian",
      label: "Hasil pertanian",
   },
   {
      value: "Bahan kimia",
      label: "Bahan kimia",
   },
   {
      value: "Tekstil/kain",
      label: "Tekstil/kain",
   },
   {
      value: "Mobil/motor",
      label: "Mobil/motor",
   },
   {
      value: "Alat berat",
      label: "Alat berat",
   },
   {
      value: "Lain-lain",
      label: "Lain-lain",
   },
];

export const getCodeDesc = (code: string, type: "PartnerType" | "ExpenseType" | "ExpenseStatus") => {
   let masters: Master[] = [];
   switch (type) {
      case "PartnerType":
         masters = entityTypes;
         break;
      case "ExpenseType":
         masters = expenseTypes;
         break;
      case "ExpenseStatus":
         masters = expenseStatuses;
   }
   masters = masters?.filter((master) => master.code === code);
   return masters.length > 0 ? masters[0].desc : "";
};

export const declineReasons: Option[] = [
   { code: DECLINE_REASON_MAINTENANCE, desc: DECLINE_REASON_MAINTENANCE_DESC },
   { code: DECLINE_REASON_UNABLE_CONTACT_CUSTOMER, desc: DECLINE_REASON_UNABLE_CONTACT_CUSTOMER_DESC },
   { code: DECLINE_REASON_PICK_UP_LOCATION_TOO_FAR, desc: DECLINE_REASON_PICK_UP_LOCATION_TOO_FAR_DESC },
   { code: DECLINE_REASON_FULL_BOOK, desc: DECLINE_REASON_FULL_BOOK_DESC },
   { code: DECLINE_REASON_INCORRECT_ADDRESS, desc: DECLINE_REASON_INCORRECT_ADDRESS_DESC },
   { code: DECLINE_REASON_OTHERS, desc: DECLINE_REASON_OTHERS_DESC },
];

export const complaintTypes: Option[] = [
   { code: COMPLAINT_TYPE_DAMAGE, desc: COMPLAINT_TYPE_DAMAGE_DESC },
   { code: COMPLAINT_TYPE_LOST, desc: COMPLAINT_TYPE_LOST_DESC },
   { code: COMPLAINT_TYPE_LATE, desc: COMPLAINT_TYPE_LATE_DESC },
];

export const complaintResolutionTypes: Option[] = [
   { code: COMPLAINT_RESOLVE_NOT_VALID, desc: COMPLAINT_RESOLVE_NOT_VALID_DESC },
   { code: COMPLAINT_RESOLVE_COMPENSATE, desc: COMPLAINT_RESOLVE_COMPENSATE_DESC },
];
