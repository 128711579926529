import * as React from "react";
import mixpanel from "mixpanel-browser";

import * as Config from "../../Config";

import { ProviderView, ServiceProviderView, ShipmentView, SearchServiceView, TransporterView, SiteView, CarrierView, ServiceView, SquareButton, ReviewView, UserView, VoucherView, CustomButton, CustomerView } from ".";

import { CacheService } from "../../service";

import * as Master from "../../reference/Master";

import * as LocationHelper from "../../helper/LocationHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";

import "./PageView.css";

function RenderProvider(props: any) {
   return props.providers.map((provider: any, i: any) => <ProviderView provider={provider} onSelect={props.onPressMore} key={i} />);
}

function RenderServiceProvider(props: any) {
   return props.serviceProviders.map((provider: any, i: any) => (
      <ServiceProviderView
         provider={provider}
         shipment={props.shipment}
         voucher={props.voucher}
         distance={props.distance}
         tollFee={props.tollFee}
         ferryFee={props.ferryFee}
         isReqTransWorker={props.isReqTransWorker}
         onPressMore={props.onPressMore}
         onPressSelect={props.onPressSelect}
         key={i}
      />
   ));
}

function RenderTransporter(props: any) {
   return props.transporters.map((transporter: any, i: any) => <TransporterView transporter={transporter} onPressMore={props.onPressMore} key={i} />);
}

function RenderCustomer(props: any) {
   return props.customers.map((customer: any, i: any) => <CustomerView customer={customer} onPressMore={props.onPressMore} key={i} />);
}

function RenderSite(props: any) {
   return props.sites.map((site: any, i: any) => <SiteView site={site} onPressMore={props.onPressMore} key={i} />);
}

function RenderVoucher(props: any) {
   return props.vouchers.map((voucher: any, i: any) => <VoucherView voucher={voucher} onPressMore={props.onPressMore} key={i} />);
}

function RenderUser(props: any) {
   return props.users.map((user: any, i: any) => <UserView user={user} onPressMore={props.onPressMore} key={i} />);
}

function RenderCarrier(props: any) {
   return props.carriers.map((carrier: any, i: any) => <CarrierView carrier={carrier} onPressMore={props.onPressMore} key={i} />);
}

function RenderShipment(props: any) {
   return props.shipments.map((shipment: any, i: any) => <ShipmentView shipment={shipment} onPressMore={props.onPressMore} key={i} />);
}

function RenderService(props: any) {
   return props.services.map((service: any, i: any) => <ServiceView service={service} onPressMore={props.onPressMore} key={i} />);
}

function RenderReview(props: any) {
   return props.reviews.map((rating: any, i: any) => <ReviewView review={rating} onPressMore={props.onPressMore} key={i} />);
}

function RenderSearchService(props: any) {
   return props.searchServices.map((searchService: any, i: any) => <SearchServiceView searchService={searchService} onPressMore={props.onPressMore} key={i} />);
}

function Navigation(props: any) {
   CacheService.setPage(props.page);
   CacheService.setPageCount(props.pageCount);

   const onSelectPage = (page: number) => {
      props.setPage(page);
      CacheService.setPage(page);
      let elm = document.getElementById("search-result");
      if (elm) {
         elm.scrollTo({
            top: 0,
            behavior: "auto",
         });
      }
   };

   let pages = [];

   let gap = window.innerWidth < 500 ? 1 : 3;

   let startPage = props.page - gap;
   let endPage = props.page + gap;

   let prevPage = props.page > 1 ? props.page - 1 : 1;
   let nextPage = props.page < props.pageCount ? props.page + 1 : props.pageCount;

   if (startPage < 1) {
      endPage += 1 - startPage;
      if (endPage > props.pageCount) {
         endPage = props.pageCount;
      }
      startPage = 1;
   }

   if (endPage > props.pageCount) {
      startPage += props.pageCount - endPage;
      if (startPage < 1) {
         startPage = 1;
      }
      endPage = props.pageCount;
   }

   for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
   }

   return (
      <div>
         {pages.length > 0 && (
            <div style={{ display: "flex" }}>
               <SquareButton page first onClick={() => onSelectPage(1)} />
               {window.innerWidth >= 425 && <SquareButton page prev onClick={() => onSelectPage(prevPage)} />}
               {startPage > 1 && <SquareButton page text="..." />}
               {pages.map((page: any, key: any) => (
                  <SquareButton page text={page} current={page === props.page} key={key} onClick={() => onSelectPage(page)} />
               ))}
               {endPage < props.pageCount && <SquareButton page text="..." />}
               {window.innerWidth >= 425 && <SquareButton page next onClick={() => onSelectPage(nextPage)} />}
               <SquareButton page last onClick={() => onSelectPage(props.pageCount)} />
            </div>
         )}
      </div>
   );
}

const onContactWa = () => {
   mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
   mixpanel.track("Event Whatsapp Admin - Transporter Not Found");

   let shipment = CacheService.getNewShipment();
   if (shipment && shipment.destinations) {
      const transportTypeDesc = shipment.transportTypeDesc;
      const deliveryDate = CalendarHelper.getDayDateMonthTime(shipment.deliveryDate);
      const weight = shipment.weightEst + " Kg";
      let originDestinations = LocationHelper.getSubRegionCity(shipment.origin?.address);
      for (let i = 0; i < shipment.destinations.length; i++) {
         const destination = shipment.destinations[i];
         originDestinations += " > " + LocationHelper.getSubRegionCity(destination.address);
      }
      window.open(
         "https://wa.me/" +
            Master.MOBILE_LINTAS +
            "?text=" +
            encodeURI("Halo Customer Service, mohon bantuan mencarikan angkutan. Jenis angkutan " + transportTypeDesc + " untuk " + originDestinations + ". Pada hari " + deliveryDate + ", berat " + weight + ".")
      );
   } else {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Mohon bantuan, tidak menemukan angkutan yang di cari. "));
   }
};

export default function PageView(props: any) {
   return (
      <div id="page-view" className="page-view">
         <div className="page-view-content">
            {props.providers && <RenderProvider {...props} />}
            {props.serviceProviders && <RenderServiceProvider {...props} />}
            {props.transporters && <RenderTransporter {...props} />}
            {props.customers && <RenderCustomer {...props} />}
            {props.sites && <RenderSite {...props} />}
            {props.users && <RenderUser {...props} />}
            {props.carriers && <RenderCarrier {...props} />}
            {props.shipments && <RenderShipment {...props} />}
            {props.services && <RenderService {...props} />}
            {props.reviews && <RenderReview {...props} />}
            {props.vouchers && <RenderVoucher {...props} />}
            {props.searchServices && <RenderSearchService {...props} />}
            {((props.providers && props.providers.length === 0) ||
               (props.serviceProviders && props.serviceProviders.length === 0) ||
               (props.transporters && props.transporters.length === 0) ||
               (props.customers && props.customers.length === 0) ||
               (props.sites && props.sites.length === 0) ||
               (props.users && props.users.length === 0) ||
               (props.carriers && props.carriers.length === 0) ||
               (props.shipments && props.shipments.length === 0) ||
               (props.services && props.services.length === 0) ||
               (props.reviews && props.reviews.length === 0) ||
               (props.vouchers && props.vouchers.length === 0) ||
               (props.searchServices && props.searchServices.length === 0)) && (
               <div className="page-view-not-found">
                  <img src="/images/doodle/not-found.png" alt="doodle" className="doodle" />
                  <div className="regular-bold-font">
                     {props.emptyText && <div>{props.emptyText}</div>}
                     {!props.emptyText && (
                        <div>
                           Maaf, hasil pencarian tidak ditemukan. &nbsp;
                           {(props.serviceProviders || props.searchServices) && (
                              <span>
                                 Hubungi Customer Service (CS) untuk bantuan.
                                 <div style={{ paddingTop: 10, display: "flex", justifyContent: "center" }}>
                                    <CustomButton icon="call" text="Hubungi CS via WA" onClick={onContactWa} />
                                 </div>
                              </span>
                           )}
                        </div>
                     )}
                  </div>
               </div>
            )}
         </div>
         <div className="page-view-navigation">
            <Navigation page={props.page} setPage={props.setPage} pageCount={props.pageCount} />
         </div>
      </div>
   );
}
