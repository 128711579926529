// Dev
// export const LINTAS_API = "http://192.168.100.56:8080";
// export const STORAGE_API = "http://192.168.100.56:8080";
// export const MAPS_API = "http://192.168.100.56:8080";
// export const POSITION_WS_API = "ws://192.168.100.56:8080/position";
// export const REFFERAL_LINK = "http://192.168.100.56:3000/booking/provider/";
// export const GA_REF = "UA-213361358-1";

// Localhost
// export const LINTAS_API = "http://localhost:8080";
// export const STORAGE_API = "http://localhost:8080";
// export const MAPS_API = "http://localhost:8080";
// export const POSITION_WS_API = "ws://localhost:8080/position";
// export const REFFERAL_LINK = "http://localhost:3000/booking/provider/";
// export const GA_REF = "UA-213361358-1";

// const IP_ADDRESS = "10.0.3.2";
// const IP_ADDRESS = "192.168.163.33";
// const IP_ADDRESS = "192.168.100.22";
// const IP_ADDRESS = "192.168.0.107";
// const IP_ADDRESS = "192.168.0.109";
// export const LINTAS_API = "http://" + IP_ADDRESS + ":8080";
// export const STORAGE_API = "http://" + IP_ADDRESS + ":8080";
// export const MAPS_API = "http://" + IP_ADDRESS + ":8080";
// export const POSITION_WS_API = "ws://" + IP_ADDRESS + ":8080/position";
// export const REFFERAL_LINK = "http://" + IP_ADDRESS + ":3000/booking/provider/";
// export const GA_REF = "UA-213361358-1";
// export const MIXPANEL_TOKEN = "70cbc39c3fec61673b8e27dc5bc3f3ec";
// export const GOOGLE_MAP_KEY = "";

// UAT
// export const LINTAS_API = "https://demo-api.lintas.app";
// export const STORAGE_API = "https://demo-api.lintas.app";
// export const MAPS_API = "https://demo-api.lintas.app";
// export const POSITION_WS_API = "wss://demo-api.lintas.app/position";
// export const REFFERAL_LINK = "https://demo.lintas.app/booking/provider/";
// export const GA_REF = "UA-213361358-1"

// PRD
export const LINTAS_API = "https://api.lintas.app";
export const STORAGE_API = "https://api.lintas.app";
export const MAPS_API = "https://api.lintas.app";
export const POSITION_WS_API = "wss://api.lintas.app/position";
export const REFFERAL_LINK = "https://lintas.app/booking/provider/";
export const GA_REF = "UA-208205616-1";
export const MIXPANEL_TOKEN = "7743ef522fd63fc56eaab665e7a04bc3";
export const GOOGLE_MAP_KEY = "AIzaSyBB2oknk0t2vNf5OCGzW8p7HPSz44RFjjE";
