import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Checkbox, message, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ProviderService, CacheService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import { ImageUpload, InputText, InputStatic, FormLayout, PlaceSelection, PlaceMapSelection } from "../common";

import { User, Provider, Image, Place } from "../../model";

import * as Master from "../../reference/Master";
import * as PlaceRef from "../../reference/Place";

import "./RegisterProvider.css";

let isAgreeTnc = false;

const typeOptions = [
   { label: "Pribadi", value: Master.ENTITY_TYPE_PERSONAL },
   { label: "Perusahaan", value: Master.ENTITY_TYPE_CORPORATE },
];

function onAgreeTncChange(e: any) {
   isAgreeTnc = e.target.checked;
}

function RegisterProvider(props: any) {
   const history = useHistory();
   const { control, errors, handleSubmit } = useForm();
   const [type, setType] = useState(Master.ENTITY_TYPE_PERSONAL);
   const [profileImg, setProfileImg] = useState<Image>();
   const [modalContext, setModalContext] = useState<ModalContextInterface>();
   const [showSelectPlace, setShowSelectPlace] = useState(false);
   const [showSelectPlaceMap, setShowSelectPlaceMap] = useState(false);
   const [place, setPlace] = useState<Place>(PlaceRef.defaultPlace);
   const identifier = CacheService.getUser().role === Master.ROLE_ADMIN ? "PIC" : "Anda";

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   const onChangeTypeOptions = (e: any) => {
      setType(e.target.value);
   };

   const onSubmit = (data: any) => {
      if (!profileImg) {
         message.info("Mohon upload/unggah foto armada");
         return;
      }

      if (!isAgreeTnc) {
         message.info("Mohon menyetujui aturan dan kondisi penggunaan sistem");
         return;
      }

      AlertHelper.alertLoading("Memproses registrasi ...", modalContext);

      const user: User = new User();
      user.name = data.userName;
      user.email = type === Master.ENTITY_TYPE_CORPORATE ? data.userEmail : data.email;
      user.mobile = data.userMobile;

      const provider: Provider = new Provider();
      provider.name = data.name;
      provider.type = type;
      provider.phone = type === Master.ENTITY_TYPE_PERSONAL ? CacheService.getMobile() : data.phone;
      provider.email = data.email;
      provider.profileImg = profileImg;
      provider.location = place;

      ProviderService.registerProvider(user, provider)
         .then((user) => {
            AlertHelper.alertSuccess("Registrasi penyedia jasa", modalContext);

            if (CacheService.getUser().role === Master.ROLE_PROVIDER) {
               history.replace("/home/login");
               props.setUser(user);
            } else {
               history.replace("/admin/provider");
            }
         })
         .catch((error: any) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <div>
                  <PlaceSelection show={showSelectPlace} setShow={setShowSelectPlace} setShowSelectPlaceMap={setShowSelectPlaceMap} place={place} setPlace={setPlace} />
                  <PlaceMapSelection show={showSelectPlaceMap} setShow={setShowSelectPlaceMap} setShowSelectPlace={setShowSelectPlace} place={place} setPlace={setPlace} />
                  <FormLayout doodle="/images/doodle/checklist.png" title="Registrasi hampir selesai!" desc="Mohon lengkapi info jasa angkutan anda!">
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <ImageUpload single reference="provider-profile" image={profileImg} fleet text="Foto Armada" setImage={setProfileImg} />

                        <Radio.Group name="type" options={typeOptions} onChange={onChangeTypeOptions} value={type} buttonStyle="solid" />

                        <InputText name="name" placeholder="Nama Jasa Angkutan" control={control} error={errors.name} />

                        {type === Master.ENTITY_TYPE_PERSONAL && <InputText name="userName" placeholder={`Nama ${identifier}`} control={control} error={errors.userName} />}

                        {type === Master.ENTITY_TYPE_PERSONAL && CacheService.getUser().role === Master.ROLE_ADMIN && <InputText name="userMobile" placeholder={`No. HP ${identifier}`} control={control} error={errors.userMobile} />}

                        {type === Master.ENTITY_TYPE_CORPORATE && <InputText name="phone" placeholder="No. Telepon Perusahaan" phone control={control} error={errors.phone} />}

                        <InputText name="email" placeholder={"Email" + (type === Master.ENTITY_TYPE_CORPORATE ? " Perusahaan" : "")} email control={control} error={errors.email} />

                        <InputStatic
                           placeholder={"Alamat" + (type === Master.ENTITY_TYPE_CORPORATE ? " Perusahaan" : "")}
                           onClick={() => {
                              setShowSelectPlace(true);
                           }}
                           value={place.address}
                        />

                        {type === Master.ENTITY_TYPE_CORPORATE && <InputText name="userName" placeholder={`Nama ${identifier}`} control={control} error={errors.userName} />}

                        {type === Master.ENTITY_TYPE_CORPORATE && CacheService.getUser().role === Master.ROLE_ADMIN && <InputText name="userMobile" placeholder={`No. HP ${identifier}`} control={control} error={errors.userMobile} />}

                        {type === Master.ENTITY_TYPE_CORPORATE && <InputText name="userEmail" placeholder={`Email ${identifier}`} email control={control} error={errors.userEmail} />}

                        <div className="register-agree-tnc">
                           <Checkbox onChange={onAgreeTncChange} className="register-agree-tnc">
                              Saya menyetujui aturan dan kondisi penggunaan sistem
                           </Checkbox>
                        </div>
                        <Button type="primary" htmlType="submit" onClick={() => setModalContext(context)}>
                           Daftar
                        </Button>
                     </form>
                  </FormLayout>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterProvider;
