import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { Button } from "antd";
import { AiOutlineCalendar } from "react-icons/ai";
import { RiPriceTag3Line } from "react-icons/ri";
import TagManager from "react-gtm-module";

import mixpanel from "mixpanel-browser";

import * as Master from "../../../reference/Master";
import * as Config from "../../../Config";

import * as CommonHelper from "../../../helper/CommonHelper";

import "./AdItem.css";

let modalContext: ModalContextInterface;

function AddItem(props: any) {
   const history = useHistory();
   const [image, setImage] = useState(Master.IMAGE_TRANSPARENT);
   const [text, setText] = useState("");

   /* const onBookNow = data => {
      CacheService.setCategory("");

      // change otp verification from initial booking to shipment confirmation
      // if (CacheService.getUser() && CacheService.getUser().customer) {
      //    //console.log('getUser: ', CacheService.getUser());
      //    history.push('/booking');
      // } else {
      //    history.push("/login/" + Master.PATH_BOOKING);
      // }

      history.push("/" + Master.PATH_BOOKING);
   }; */

   const onCheckPrice = () => {
      // window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau cek harga"));
      modalContext?.setCheckPrice(true);
      // history.push("/booking");

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Check Price");

      // gtm();
   };

   const onBookNow = () => {
      // window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau pesan angkutan"));
      modalContext?.setNewShipment(true);
      // history.push("/booking");

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Book Now");

      // gtm();
   };

   const gtm = () => {
      // gtm conversion
      const tagManagerArgs = {
         gtmId: "GTM-MW67KPL",
      };
      TagManager.initialize(tagManagerArgs);
   };

   useEffect(() => {
      if (image === Master.IMAGE_TRANSPARENT) {
         let imageUrl = props.ad.fields.imageUrl;

         if (window.innerWidth < 1000) {
            imageUrl = imageUrl.replace(".jpg", "");
            imageUrl = imageUrl + "-small.jpg";
         }

         CommonHelper.getImage(imageUrl, setImage);
      }
   }, [image, props.ad.fields.imageUrl]);

   useEffect(() => {
      if (image) {
         setText(props.ad.fields.text);
      }
   }, [image, props.ad.fields.text]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="ad-item-panel">
                  <div
                     className="ad-item-img"
                     style={{
                        backgroundImage: `url(${image})`,
                     }}
                  >
                     <div className="ad-item-text">
                        <span>{text}</span>
                     </div>
                  </div>
                  {text && (
                     <div className="ad-item-btn-panel">
                        <Button className="ad-item-btn price-check btn-price-check" type="primary" icon={<RiPriceTag3Line className="add-item-icon" />} size="large" onClick={onCheckPrice}>
                           Cek Harga
                        </Button>
                        <Button className="ad-item-btn  btn-order" type="link" icon={<AiOutlineCalendar className="add-item-icon" />} size="large" onClick={onBookNow}>
                           Pesan Angkutan
                        </Button>
                     </div>
                  )}
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default AddItem;
