import { ErrorService } from ".";
import { Place } from "../model";

import * as PlaceRef from "../reference/Place";

import * as Config from "../Config";

const axios = require("axios");

class AuthService {
   async doRequest(request: any) {
      let response;
      try {
         //console.log('request: ', JSON.stringify(request));
         response = await axios(request);
         //console.log('response: ', JSON.stringify(response));
      } catch (error) {
         ErrorService.log(error);
         throw error;
      }
      return response;
   }

   async getGooglePlace(input: any) {
      let request = {
         method: "get",
         url: Config.MAPS_API + "/maps/api/place/textsearch/json?input=" + input + "&inputtype=textquery&region=id&fields=adr_address,formatted_address,name,geometry&language=id&key=" + Config.GOOGLE_MAP_KEY,
      };
      let response = await this.doRequest(request);

      // console.log("response: ", JSON.stringify(response));
      return response.data;
   }

   async getOsmPlace(input: any) {
      let request = {
         method: "get",
         url: "https://nominatim.openstreetmap.org/search?q=" + input + "&country=Indonesia&format=json&addressdetails=0&pretty=0&accept-language=ID",
      };
      let response = await this.doRequest(request);

      // console.log("response: ", JSON.stringify(response));

      let places: any[] = [];
      if (response.data) {
         places = response.data
            .filter((p: any) => p.display_name.includes("Indonesia"))
            .map((p: any) => {
               let name = "";
               let formatted_address = "";
               if (p.display_name) {
                  const addressItems = p.display_name.split(", ");
                  // console.log("address: ", p.display_name);
                  // console.log("address length: ", addressItems.length);
                  if (addressItems.length > 0) {
                     name = addressItems[0];
                     formatted_address = name;
                  }
                  if (addressItems.length > 2) {
                     let address = [];
                     if (isNaN(addressItems[addressItems.length - 2])) {
                        address = addressItems.slice(1, addressItems.length - 1);
                     } else {
                        address = addressItems.slice(1, addressItems.length - 2);
                     }
                     formatted_address = address.join(", ");
                  }
                  if (addressItems.length === 3) {
                     formatted_address = name + ", " + formatted_address;
                  }
               }
               let place = {
                  name: name,
                  formatted_address: formatted_address,
                  geometry: {
                     location: {
                        lat: parseFloat(p.lat),
                        lng: parseFloat(p.lon),
                     },
                  },
               };
               console.log("place: ", JSON.stringify(place, null, 2));
               return place;
            });
      }

      return { results: places };
   }

   async getGooglePlaceFromGeoLoc(input: any) {
      let request = {
         method: "get",
         url: Config.MAPS_API + "/maps/api/geocode/json?latlng=" + input.lat + "," + input.lng + "&fields=formatted_address,name,geometry&language=id&key=" + Config.GOOGLE_MAP_KEY,
      };
      let response = await this.doRequest(request);
      let data = response.data;

      // console.log("response: ", JSON.stringify(data));

      let place: Place =
         data.results.length > 0
            ? {
                 name: "",
                 address: data.results[0].formatted_address,
                 lat: data.results[0].geometry.location.lat,
                 lng: data.results[0].geometry.location.lng,
              }
            : {
                 name: "",
                 address: "",
                 lat: PlaceRef.defaultLatLng.lat,
                 lng: PlaceRef.defaultLatLng.lng,
              };

      return place;
   }

   async getOsmPlaceFromGeoLoc(input: any) {
      console.log("getOsmPlaceFromGeoLoc: ", JSON.stringify(input));

      let request = {
         method: "get",
         url: "https://nominatim.openstreetmap.org/reverse?lat=" + input.lat + "&lon=" + input.lng + "&format=json&accept-language=id",
      };
      let response = await this.doRequest(request);
      let data = response.data;

      // console.log("response: ", JSON.stringify(data));

      let place: Place =
         data && data.display_name
            ? {
                 name: "",
                 address: getOsmDisplayName(data.display_name),
                 lat: input.lat,
                 lng: input.lng,
              }
            : {
                 name: "",
                 address: "",
                 lat: input.lat,
                 lng: input.lng,
              };

      return place;
   }
}

function getOsmDisplayName(address: string) {
   let formatted_address = "";
   const addressItems: any[] = address.split(", ");
   // console.log("address: ", p.display_name);
   // console.log("address length: ", addressItems.length);
   if (addressItems.length > 2) {
      let address = [];
      if (isNaN(addressItems[addressItems.length - 2])) {
         address = addressItems.slice(0, addressItems.length - 1);
      } else {
         address = addressItems.slice(0, addressItems.length - 2);
      }
      formatted_address = address.join(", ");
   }

   return formatted_address;
}

const Service = new AuthService();

export default Service;
